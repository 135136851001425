<template>
    <div>
        <div class="row container">
            <div class="col-sm-6">
                <h4>SPTRD</h4>
                <h4>SURAT PEMBERITAHUAN RETRIBUSI DAERAH</h4>
            </div>
            <div class="col-sm-6">
                <h4 class="float-right">
                    <i class="fa fa-info-circle"></i> Step 2 - MASA RETRIBUSI
                </h4>
            </div>
        </div>
        <hr />
        <fieldset>
            <div class="row">
                <div class="col-sm-6">
                    <legend class="mb-1">Identitas Wajib Retribusi</legend>
                </div>
            </div>
            <div class="form-group row mb-1 mt-3">
                <label class="col-md-3 col-form-label font-weight-bold"
                    >Sumber Retribusi</label
                >
                <div class="col-md-3">
                    <input
                        type="email"
                        class="form-control"
                        v-model="sumber"
                        readonly
                    />
                </div>
            </div>
            <div class="form-group row mb-1">
                <label class="col-md-3 col-form-label font-weight-bold"
                    >NPWRD</label
                >
                <div class="col-md-3">
                    <input
                        type="text"
                        class="form-control"
                        v-model="npwrdDetail.no_pokok"
                        readonly
                    />
                </div>
            </div>
            <div class="form-group row mb-1">
                <label class="col-md-3 col-form-label font-weight-bold"
                    >NIB
                </label>
                <div class="col-md-3">
                    <input
                        type="text"
                        class="form-control"
                        v-model="npwrdDetail.no_induk"
                        readonly
                    />
                </div>
            </div>
            <div class="form-group row mb-1">
                <label class="col-md-3 col-form-label font-weight-bold"
                    >Nama
                </label>
                <div class="col-md-3">
                    <input
                        type="text"
                        class="form-control"
                        v-model="npwrdDetail.nama"
                        readonly
                    />
                </div>
            </div>
            <div class="form-group row mb-1">
                <label class="col-md-3 col-form-label font-weight-bold"
                    >Nomor Handphone
                </label>
                <div class="col-md-3">
                    <input
                        type="text"
                        class="form-control"
                        v-model="npwrdDetail.nomp"
                        readonly
                    />
                </div>
            </div>
            <div class="form-group row mb-1">
                <label class="col-md-3 col-form-label font-weight-bold"
                    >Nomor Telepon
                </label>
                <div class="col-md-3">
                    <input
                        type="text"
                        class="form-control"
                        v-model="npwrdDetail.notelepon"
                        readonly
                    />
                </div>
            </div>
            <div class="form-group row mb-1">
                <label class="col-md-3 col-form-label font-weight-bold"
                    >Email
                </label>
                <div class="col-md-3">
                    <input
                        type="email"
                        class="form-control"
                        v-model="npwrdDetail.email"
                        readonly
                    />
                </div>
            </div>
            <div class="form-group row mb-1">
                <label class="col-md-3 col-form-label font-weight-bold"
                    >Alamat</label
                >
                <div class="col-md-5">
                    <b-form-textarea
                        id="textarea"
                        v-model="npwrdDetail.alamat"
                        rows="6"
                        disabled
                    ></b-form-textarea>
                </div>
            </div>
            <hr />
        </fieldset>
        <form
            @submit.prevent="store"
            @keydown="form.onKeydown($event)"
            autocomplete="off"
        >
            <div class="row">
                <div class="col-sm-6">
                    <legend class="mb-1">Masa Retribusi</legend>
                </div>
            </div>
            <div class="form-group row mb-1">
                <label class="col-md-3 col-form-label font-weight-bold"
                    >Masa Retribusi <span class="text-danger">*</span></label
                >
                <div class="col-md-2">
                    <v-select
                        label="nama"
                        :reduce="(nama) => nama.id"
                        :options="bulanOptions"
                        v-model="form.masa"
                        placeholder="-Pilih-"
                        :disabled="isDisable"
                    ></v-select>
                    <em
                        v-if="form.errors.has('masa')"
                        class="form-text text-danger"
                        ><i class="fa fa-exclamation-triangle"></i>
                        {{ errors.masa[0] }}</em
                    >
                </div>
                <div class="col-md-2">
                    <input
                        type="text"
                        class="form-control"
                        v-model="form.tahun"
                        disabled
                    />
                    <em
                        v-if="form.errors.has('tahun')"
                        class="form-text text-danger"
                        ><i class="fa fa-exclamation-triangle"></i>
                        {{ errors.tahun[0] }}</em
                    >
                </div>
            </div>
            <hr />
            <span>
                <b-button
                    variant="primary"
                    type="submit"
                    :disabled="saveDisabled"
                >
                    <span v-if="isLoading"
                        ><b-spinner
                            variant="light"
                            small
                            label="loading"
                        ></b-spinner
                    ></span>
                    <i class="fa fa-save" v-else></i>
                    Simpan
                </b-button>
            </span>
            <span class="float-right">
                <span class="px-2">
                    <b-button variant="danger" @click="prevButton">
                        <i class="fa fa-arrow-left"></i> Sebelumnya
                    </b-button>
                </span>
                <span>
                    <b-button
                        variant="success"
                        @click="nextButton"
                        :disabled="nextDisabled"
                    >
                        <i class="fa fa-arrow-right"></i> Selanjutnya
                    </b-button>
                </span>
            </span>
        </form>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars

import axios from "axios";
import hapusStorage from '@/helper/hapusLocalStore'

export default {
    mixins: [hapusStorage],
    data() {
        return {
            npwrd: [],
            npwr1: "",
            npwr2: "",
            npwr3: "",
            npwpd_arr: [],
            idNpwrd: JSON.parse(localStorage.getItem("npwrdId")),
            sumber: "",
            npwrdDetail: new Form({
                no_induk: "",
                alamat: "",
                nama: "",
                nomp: "",
                notelepon: "",
                email: "",
                no_pokok: ""
            }),
            form: new Form({
                npwrd_id: "",
                masa: "",
                tahun: "",
            }),
            options: {
                format: "YYYY-MM-D",
                useCurrent: false,
            },
            retribusiOptions: [],
            tahun: "",
            bulanOptions: [],
            bulan: [
                {
                    id: "01",
                    nama: "Januari",
                },
                {
                    id: "02",
                    nama: "Februari",
                },
                {
                    id: "03",
                    nama: "Maret",
                },
                {
                    id: "04",
                    nama: "April",
                },
                {
                    id: "05",
                    nama: "Mei",
                },
                {
                    id: "06",
                    nama: "Juni",
                },
                {
                    id: "07",
                    nama: "Juli",
                },
                {
                    id: "08",
                    nama: "Agustus",
                },
                {
                    id: "09",
                    nama: "September",
                },
                {
                    id: "10",
                    nama: "Oktober",
                },
                {
                    id: "11",
                    nama: "November",
                },
                {
                    id: "12",
                    nama: "Desember",
                },
            ],
            kode: "",
            errors: [],
            saveDisabled: false,
            isLoading: false,
            nextDisabled: true,
            isDisable: false,
            sptId: "",
            no_pokok: "",
            no_induk: "",
            sptArr: []
        };
    },
    mounted() {
        if (JSON.parse(localStorage.getItem("sptId")) !== null) {
            this.sptId = JSON.parse(localStorage.getItem("sptId"));
            this.getSpt(this.sptId);
            this.getNpwrd(this.idNpwrd)
            this.isDisable = true;
            this.nextDisabled = false;
            this.saveDisabled = true;
        } else if (this.$route.name === "NextStep2") {
            this.getSpt(this.$route.params.id);
            this.isDisable = true;
            this.nextDisabled = false;
            this.saveDisabled = true;
        } else {
            this.getNpwrd(this.idNpwrd)
        }
        this.getTahun();
    },
    methods: {
        // tambah data
        store() {
            this.isLoading = true;
            this.form.npwrd_id = this.idNpwrd;
            this.form
                .post("/api/transaksi/spt")
                .then((response) => {
                    if (this.form.successful) {
                        this.saveDisabled = true;
                        this.isLoading = false;
                        this.nextDisabled = false;
                        this.isDisable = true;
                        this.sptId = response.data.data.id;
                        this.$swal({
                            title:
                                "MASA RETRIBUSI Berhasil Dijalankan",
                            text: 'Silahkan tekan "Selanjutnya"',
                            icon: "success",
                            showCloseButton: true,
                            confirmButtonText:
                                'Selanjutnya <i class="fa fa-arrow-circle-right"></i>',
                            allowOutsideClick: false,
                        }).then((result) => {
                            if (result.isConfirmed) {
                                this.nextButton();
                            }
                        });
                        /*  this.form.reset()
                    this.kodeNama= ''
                    this.message = false */
                    }
                })
                .catch((error) => {
                    this.isLoading = false;
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                        this.saveDisabled = false;
                        this.isLoading = false;
                        this.nextDisabled = true;
                        this.isDisable = false;
                    } else if (error.response.status === 401) {
                        // hapus storage mixins
                        this.clearAll()
                    }
                });
        },
        prevButton() {
            if (this.$route.name === "NextStep2") {
                this.$router.push({
                    name: "NextStep1",
                    params: { id: this.$route.params.id },
                });
            } else {
                if (JSON.parse(localStorage.getItem("no_pokok"))) {
                    this.$store.dispatch(
                        "cekNpwrd/detail",
                        JSON.parse(localStorage.getItem("no_pokok"))
                    );
                } else if (JSON.parse(localStorage.getItem("no_induk"))) {
                    this.$store.dispatch(
                        "cekNpwrd/detailInduk",
                        JSON.parse(localStorage.getItem("no_induk"))
                    );
                }
                this.$router.push({ name: "Step1" });
                if (this.no_pokok) {
                    localStorage.setItem("no_pokok", this.no_pokok);
                } else if (this.no_induk) {
                    localStorage.setItem("no_induk", this.no_induk);
                }
                localStorage.setItem("sptId", this.sptId);
                this.$store.commit("cekNpwrd/isPrev", true);
            }
        },
        nextButton() {
            if (this.$route.name === "NextStep2") {
                this.$router.push({ name: "NextStep3", params: { id: this.$route.params.id }})
            } else {
                this.$router.push({ name: "Step3" });
                localStorage.setItem("sptId", this.sptId);
                localStorage.setItem("npwrdId", this.idNpwrd);
            }
        },
        // Tahun berjalan
        getTahun() {
            const now = new Date();
            this.form.tahun = String(now.getFullYear());
            this.bulan.forEach((items) => {
                if (now.getMonth() + 1 - 1 === parseInt(items.id)) {
                    this.bulanOptions.push(items);
                } else if (now.getMonth() + 1 === parseInt(items.id)) {
                    this.bulanOptions.push(items);
                } else if (now.getMonth() + 1 + 1 === parseInt(items.id)) {
                    this.bulanOptions.push(items);
                }
            });
        },
        // data spt
        getSpt(id) {
            axios
                .get("/api/transaksi/spt/" + id)
                .then((response) => {
                    const items = response.data.data;
                    if (this.$route.name === "NextStep2") {
                        this.getNpwrd(items.npwrd_id)
                    }
                    this.form.fill(items);
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        // hapus storage mixins
                        this.clearAll()
                    }
                });
        },

        // data npwrd
        getNpwrd (id) {
            axios
            .get("/api/wajib-retribusi/npwrd/" + id)
            .then((response) => {
                this.npwrd = response.data.data;
                this.npwrdDetail.fill(this.npwrd);
                this.npwrdDetail.alamat =
                    this.npwrd.alamat +
                    "\nKel. " +
                    this.npwrd.wilayah_kelurahan.nama +
                    "\nKec. " +
                    this.npwrd.wilayah_kelurahan.wilayah_kecamatan.nama +
                    "\n" +
                    this.npwrd.wilayah_kelurahan.wilayah_kecamatan
                        .wilayah_kabkota.nama +
                    "\n" +
                    this.npwrd.wilayah_kelurahan.wilayah_kecamatan
                        .wilayah_kabkota.wilayah_provinsi.nama +
                    "\n" +
                    this.npwrd.kodepos;
                if (JSON.parse(localStorage.getItem("no_pokok"))) {
                    this.no_pokok = this.npwrd.no_pokok;
                } else if (JSON.parse(localStorage.getItem("no_induk"))) {
                    this.no_induk = this.npwrd.no_induk;
                }
                if (this.npwrd.jenis < 41) {
                    this.sumber = "Badan";
                } else {
                    this.sumber = "Perorangan";
                }
                const split = this.npwrd.no_pokok.split("");
                let first = [];
                let second = [];
                let third = [];
                let npwpd_arr = [];
                for (let item = 0; item < split.length; item++) {
                    if (item <= 1) {
                        first.push(split[item]);
                    } else if (item > 1 && item <= 7) {
                        second.push(split[item]);
                    } else {
                        third.push(split[item]);
                    }
                }
                npwpd_arr.push(first.join(""));
                this.npwr1 = first.join("");
                npwpd_arr.push(second.join(""));
                this.npwr2 = second.join("");
                npwpd_arr.push(third.join(""));
                this.npwr3 = third.join("");
                this.npwpd_arr = npwpd_arr.join(".");
            })
            .catch((error) => {
                if (error.response.status === 401) {
                    // hapus storage mixins
                    this.clearAll()
                }
            });
        }
    },
    beforeRouteLeave(to, from, next) {
        localStorage.setItem("sptId", null);
        localStorage.setItem("npwrdId", null);
        next();
    },
};
</script>
